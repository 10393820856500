@media all and (max-width:1280px) {
}

@media all and (max-width:1199px) {
}

@media all and (max-width:1024px) {
}

@media all and (max-width:991px) {
    #sidebarToggle {display:none !important;}
    #sidebarClose {color:#333; font-size:16px !important; top:5px; right:2px;}
    .nav-item.dropdown a, .nav-item.dropdown a i {color:#fff !important;}
    .dropdown-menu a.dropdown-item, .dropdown-menu a.dropdown-item i {color: #141414 !important;}
    .rw-pass-setting {padding-left:0px; padding-right:0px;}
}


@media all and (max-width:800px) {
}


@media all and (max-width:768px) {
    .remember_forgot {overflow:hidden;}
    .remember_forgot ul {display:block; float:left; margin-bottom:0px;}
    .remember_forgot ul li {display:block;}
    .login_form .left {display:flex;}
    .subscription-add .row {display:block;}
    .subscription-add .box {max-width:100%; margin-bottom:15px; border-right:none; border-bottom:1px solid #dddfd4; padding-bottom:10px;}
    .subscription-add .box:last-child {border-bottom:none;}
    .profile-page .rw-seperator {padding:10px;}
    .certificate-revocation-add-page .card-body {padding:15px 0px;} 
    .table td small {width:110px;}
    .manage_distributor_img {white-space:nowrap;}
}

@media all and (max-width:767px) {
    .login {background: #ffffff;}
    .login_form {box-shadow:none;}
    .login .vhheight {margin:0px;}
    .login_form .right {padding:0px;}
    .app-sidebar, .off-canvas-sidebar {box-shadow:none;}
    .table td small {width:auto;} 
}


@media all and (max-width:640px) {
}


@media all and (max-width:601px) {
}


@media all and (max-width:575px) {
    .chat-history-filter-askbtn {margin-bottom:10px;}
    .chat-history-filter-askbtn .commonsavebtn {margin-right:0px !important;}
    .sitesetting-box {margin-bottom:10px !important;}
}


@media all and (max-width:480px) {
}

@media all and (max-width:414px) {
    .verfication_request_ttl-mob .commonsavebtn {display:block; width:100%;}
    .verfication_request_ttl-mob .commonsavebtn:first-child {margin-bottom:10px !important;}
}

@media all and (max-width:360px) {
}