@font-face {
    font-family: sf_pro_displayregular;
    src: url(../src/assets/fonts/fontsfree-net-sfprodisplay-regular-webfont.woff2) format("woff2"), url(../src/assets/fonts/fontsfree-net-sfprodisplay-regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
body,
html {
    height: 100%;
    font-family: sf_pro_displayregular !important;
}
body {
    margin: 0;
    font-family: sf_pro_displayregular !important;
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 24px;
    color: #141414;
}
.mat-tab-group,
.mat-tab-label,
.mat-tab-link {
    font-family: sf_pro_displayregular !important;
}
* {
    outline: 0;
}
hr {
    border-color: #dddfd4 !important;
}
.pad0 {
    padding: 0 !important;
}
h1 {
    font-size: 24px;
    font-weight: 600;
}
h2 {
    font-size: 22px;
    font-weight: 600;
}
h3 {
    font-size: 20px;
    font-weight: 600;
}
h4 {
    font-size: 16px;
    font-weight: 600;
}
h5 {
    font-size: 16px;
    font-weight: 600;
}
p {
    font-weight: 300;
}
.table tr {
    border: 1px solid #dee2e6;
}
tr.mat-header-row {
    height: auto !important;
}
.table thead {
    background-color: #eaeaea;
}
.table td,
.table th {
    padding: 0.75rem !important;
    vertical-align: middle !important;
    line-height: 1.25;
}
.table td {
    padding: 0 0.75rem !important;
}
.table th {
    font-weight: 600;
    color: #000 !important;
    font-size: 14px;
}
.error-verify .alert-warning {
    border-color: #e2835e !important;
    background-color: #ffcdba !important;
    color: #bb3500 !important;
    padding: 5px 19px;
    font-size: 17px;
}
.commonbackbtn {
    border-radius: 3px !important;
    font-size: 14px;
    color: #141414;
    outline: 0 !important;
    background: #dddfd4;
    margin-right: 10px !important;
    font-weight: 400;
    line-height: 33px !important;
}
.commonbackbtn:focus,
.commonbackbtn:hover {
    box-shadow: none !important;
    outline: 0;
    color: #141414 !important;
}
.commonsavebtn {
    border-radius: 3px !important;
    font-size: 14px;
    background: #3fb0ac;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    color: #fff !important;
    outline: 0 !important;
    font-weight: 400;
    line-height: 33px !important;
}
.commonuploadbtn {
    border-radius: 3px !important;
    font-size: 14px;
    background: #e3aa36;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    background: linear-gradient(to right, #fbbb24, #e3aa36);
    color: #fff;
    outline: 0 !important;
    font-weight: 400;
    line-height: 33px !important;
}
.circlebtn {
    background: #ff416c;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
    background: linear-gradient(to right, #ff4b2b, #ff416c);
}
.mat-form-field {
    width: 100%;
}
.breadcrumb-custom .breadcrumb {
    background-color: transparent;
}
.wrapper .content-header {
    margin: 0;
}
.breadcrumb-custom .breadcrumb {
    margin: 0;
    padding: 0;
}
.commonheader_breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.commonheader_breadcrumb .content-header {
    font-size: 22px;
}
.commonheader_breadcrumb .breadcrumb-custom-left span {
    font-size: 12px;
    vertical-align: middle;
    font-weight: 500;
    margin-left: 5px;
    color: #686868;
    background-color: #fff;
    padding: 3px 12px;
    border-radius: 30px;
    border: 1px solid#ccc;
    box-shadow: 0 1px 0 #ddd;
}
.card-header {
    border-bottom: 1px solid #e4e4e4 !important;
}
.mat-tab-body-content {
    overflow: hidden !important;
}
.mat-tab-label .mat-tab-label-content {
    font-size: 16px;
    font-weight: 400;
    color: #000;
}
app-customizer {
    display: none !important;
}
form label {
    letter-spacing: 0.5px !important;
    text-transform: unset;
    color: #000 !important;
    font-weight: 500 !important;
}
.off-canvas-sidebar .sidebar-background,
app-sidebar .sidebar-background {
    background-image: none !important;
}
.table td,
.table th {
    vertical-align: middle;
}
.modal-body {
    padding: 30px;
}
.modal-header .close {
    outline: 0 !important;
}
.modal-title {
    font-weight: 600;
}
.modal-body span {
    word-break: break-all;
}
.mat-mini-fab .mat-button-wrapper {
    padding: 0 !important;
}
.overflow_txt p {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    margin: 0;
}
form {
    width: 100%;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #009da0;
}
.modal-dialog {
    height: 100vh;
    display: flex;
    align-items: center;
}
.modal-header .close {
    background-color: #009da0;
    color: #fff;
    opacity: 1;
}
.readonly-section .mat-form-field-appearance-outline .mat-form-field-outline-end,
.readonly-section .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border: none !important;
}
.readonly-section .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: none;
}
.readonly-section .mat-form-field-label-wrapper {
    font-size: 20px;
    font-weight: 600;
}
.readonly-section .upload-img {
    display: none;
}
.readonly-section .viewbox {
    margin-bottom: 15px;
}
.readonly-section.viewbox {
    margin-bottom: 15px;
}
.readonly-section .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0;
}
.maintenance_mode {
    display: flex;
    align-items: center;
}
.maintenance_mode span {
    margin-left: 10px;
}
.maintenance_mode .mat-slide-toggle {
    margin-left: 10px;
}
textarea.mat-input-element {
    resize: none !important;
}
.badge {
    text-transform: capitalize;
    font-size: 80%;
    font-weight: 400;
    border-radius: 35px;
    border: 1px solid #333;
    background: 0 0 !important;
}
.badge-primary {
    color: #007bff;
    border-color: rgba(0, 123, 255, 0.2);
}
.badge-secondary {
    color: #6c757d;
    border-color: rgba(108, 117, 125, 0.2);
}
.badge-success {
    color: #28a745;
    border-color: rgba(40, 167, 69, 0.2);
}
.badge-danger {
    color: #dc3545;
    border-color: rgba(220, 53, 69, 0.2);
}
.badge-warning {
    color: #ffc107;
    border-color: rgba(255, 193, 7, 0.2);
}
.badge-info {
    color: #17a2b8;
    border-color: rgba(23, 162, 184, 0.2);
}
.w-break {
    word-break: break-all;
}
.text-bold-600 {
    color: #000;
}
.table td small {
    display: block;
    color: #929191;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
.manage_distributor_img img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
    margin-right: 10px;
    border: 1px solid #ccc;
}
.app-sidebar .logo,
.off-canvas-sidebar .logo {
    padding: 15px;
}
.mat-table {
    font-family: sf_pro_displayregular !important;
}
.mat-table tr td a {
    margin: 0 2px 0 0 !important;
}
#user-profile .profile-with-cover .profil-cover-details {
    margin-top: -80px !important;
}
#user-profile .profile-with-cover {
    padding-bottom: 15px;
}
.common_forms {
    padding: 0 30px;
}
.nav-toggle {
    color: #000 !important;
}
.app-sidebar .logo img,
.login_elements img {
    width: 180px;
}
.mat-placeholder-required {
    color: red;
}
.media iframe,
.media img {
    margin-right: 0 !important;
}
.profile-img {
    max-width: 160px;
    position: relative;
    width: 100%;
    height: 160px;
    float: none;
    margin: 0 auto 10px auto;
}
.upload-img {
    bottom: 0;
    position: absolute;
    right: 9px;
}
.Profile-edit-img {
    bottom: 0;
    position: relative;
    right: 0;
}
.file-upload {
    border-radius: 50px;
    bottom: 0;
    cursor: pointer;
    height: 45px;
    opacity: 0;
    position: absolute;
    right: 8px;
    width: 49px;
    z-index: 9;
}
.browse_div .form-control {
    height: calc(1.9em + 0.75rem + 2px);
}
.browse_div .img-box {
    height: 200px;
    width: 200px;
}
.browse_div .img-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.browse_div .commonsavebtn,
.browse_div .commonsavebtn:focus,
.browse_div .commonsavebtn:hover {
    color: #fff;
}
.mat-tab-body {
    padding: 30px;
}
.rw-pass-setting {
    float: none;
    margin: 0 auto;
}
.rw-seperator {
    border: 1px dashed #000;
    padding: 30px;
}
.mat-tab-body-wrapper {
    padding: 0;
}
.browse_div .imgbox {
    height: 225px;
}
.browse_div img {
    width: 100%;
    border: 5px solid #f6f6f6;
    height: 100%;
    object-fit: contain;
}
.browse_div label {
    font-size: 14px !important;
}
.cart-section-block {
    max-width: 780px;
    text-align: left;
    width: 100%;
    position: relative;
}
.cart-section-block::before {
    background: #ccc;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    right: 0;
    position: absolute;
    text-align: left;
    top: 13px;
    width: 75%;
}
.cart-step-one {
    display: inline-block;
    position: relative;
    width: 23%;
}
.step-one {
    background: #fff;
    border: 1px solid#009da0;
    display: inline-block;
    padding-top: 0;
    text-align: center;
}
.step-active {
    background: #009da0;
    border: 1px solid #009da0;
}
.num-step {
    color: #009da0;
    font-size: 15px;
    display: block;
    padding: 0 15px;
}
.step-active .num-step {
    color: #fff;
}
.num-step-hide {
    display: none;
}
.step-tick {
    color: #fff;
    padding: 0 15px;
}
.name-step {
    bottom: -33px;
    color: #545454;
    font-family: ralewaymedium;
    font-size: 16px;
    left: 0;
    position: absolute;
}
.name-step-confirm {
    top: 60px;
    left: 0;
    position: absolute;
    display: block;
    color: #545454;
    font-family: ralewaymedium;
    font-size: 15px;
    width: 100%;
}
.step-tick > img {
    width: 22px;
}
.name-step-secu {
    bottom: -33px;
    left: -24px;
    position: absolute;
    color: #545454;
    font-family: ralewaymedium;
    font-size: 16px;
}
.cart-add-btn-con {
    margin-top: 0;
}
.login-sign-head {
    font-size: 20px;
    color: #58ba47;
    font-family: ralewaymedium;
    margin: 20px -30px;
    background-color: #f9f9f9;
    padding: 10px 20px;
}
.check-bx input.css-checkbox[type="checkbox"] + label.css-label {
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 23px;
    padding-left: 30px;
    vertical-align: middle;
    color: #504f4f;
    font-family: ralewaymedium;
    font-weight: 400;
    line-height: 22px;
}
.autocomplete_row .mat-chip-list {
    width: 100%;
}
.autocomplete_row .mat-standard-chip {
    display: flex;
    align-items: center;
    position: relative;
}
.autocomplete_row .mat-chip-list-wrapper {
    display: block;
}
.autocomplete_row .mat-standard-chip .mat-chip-remove.mat-icon {
    position: absolute;
    right: 10px;
}
.login {
    background: linear-gradient(rgba(0, 0, 0), rgba(0, 0, 0));
    background-size: cover;
}
.login .vhheight {
    justify-content: center;
    height: 100vh;
    align-items: center;
}
.login_form {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    align-items: center;
    border-radius: 10px;
}
.login_form .left {
    border-radius: 10px 0 0 10px;
    padding: 54px 30px 30px;
    background-color: #e9f0f2;
}
.login_form .left img {
    border-radius: 10px 0 0 10px;
    object-fit: contain;
}
.login_form .right {
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    padding: 30px 30px;
}
.login_form .right h1 {
    color: #009da0;
}
.login_form .right h1 span {
    font-weight: 300;
    color: #000;
}
.loginbtn {
    border-radius: 25px !important;
    font-size: 18px;
    padding: 3px 30px !important;
    background: #009da0;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    background: linear-gradient(to right, #0fb0b3, #169b9e);
    color: #fff;
    outline: 0 !important;
}
.remember_forgot ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.remember_forgot label {
    margin: 0;
    font-weight: 300;
    color: #000;
    font-size: 14px;
}
.remember_forgot a {
    color: #000;
    font-weight: 300;
    color: #000;
}
.loginbtndiv .verify-certificate {
    cursor: pointer;
    font-weight: 300;
    padding-bottom: 6px;
    display: block;
}
.register_link {
    color: #303030;
    font-weight: 300;
}
.register_form .loginbtndiv a {
    display: block;
    color: #303030;
}
.forgot_password .inner_forgot_password {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
}
.forgot_password .inner_forgot_password .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0;
}
.forgot_password .inner_forgot_password h3 {
    font-weight: 400;
}
.forgot_password .inner_forgot_password p {
    color: #8c8c8c;
}
.forgot_password .mat-form-field-subscript-wrapper {
    position: inherit !important;
}
.timeline {
    position: relative;
    padding: 21px 0 10px;
    margin-top: 4px;
    margin-bottom: 30px;
}
.timeline .line {
    position: absolute;
    width: 4px;
    display: block;
    background: currentColor;
    top: 40px;
    bottom: 0;
    margin-left: 30px;
    z-index: 1;
}
.timeline .card {
    position: relative;
    margin: 10px 0 21px 70px;
    clear: both;
}
.timeline .card .card-header.icon * {
    font-size: 20px;
    vertical-align: middle;
    line-height: 40px;
}
.timeline .card .card-header.icon {
    position: absolute;
    left: -59px;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border-radius: 50%;
    text-align: center;
    float: left;
    z-index: 1;
    color: #fff;
    background: #ff416c;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
    background: linear-gradient(to right, #ff4b2b, #ff416c);
}
.timeline .card-outline {
    border-color: transparent;
    background: 0 0;
    box-shadow: none;
}
.timeline .card-outline .card-footer,
.timeline .card-outline .card-heading:not(.icon) {
    display: none;
}
.timeline .content_timeline {
    background: #f0f1f3;
    color: #777;
}
.timeline .content_timeline::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ff416c;
    top: 12px;
    left: -10px;
}
.timeline .content_timeline .heading {
    padding: 10px;
    background: #ff416c;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
    background: linear-gradient(to right, #ff4b2b, #ff416c);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}
.timeline .content_timeline .heading ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.timeline .content_timeline .innercontent {
    padding: 10px;
}
.timeline .content_timeline .innercontent ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.timeline .content_timeline .innercontent ul li {
    cursor: pointer;
}
.timeline .popover {
    border-color: #c4c4c4;
}
.timeline .popover .popover-header {
    background-color: #fff;
    border: none;
    color: #333;
    font-weight: 600;
    font-size: 16px;
}
.timeline .popover .arrow {
    z-index: 1 !important;
    margin: 1px -7px;
}
aw-wizard .wizard-steps.horizontal {
    width: 60% !important;
    margin: 0 auto;
}
.previous_unit_calc {
    display: flex;
    justify-content: center;
    align-items: center;
}
.user_batches_step2 {
    background-color: #f5f7fa;
    padding: 30px 46px 10px 30px;
}
.commonrow_steps {
    width: 100%;
}
.commonrow_steps .right {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.commonrow_steps .right .valuediv {
    width: 100%;
    margin-right: 10px;
}
a {
    color: #3fb0ac;
}
.mat-table tr td a {
    margin: 0;
}
.mat-checkbox label {
    margin: 0;
}
.badge-pill {
    padding: 7px 10px;
}
.circleeditbtn {
    background: #3fb0ac !important;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    outline: 0 !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
}
.circleeditbtn i {
    font-size: 16px;
}
.profile {
    border-radius: 50%;
}
.mat-menu-panel .mat-menu-content button a {
    display: flex;
    align-items: center;
    color: #212529;
}
.mat-icon-button,
.mat-menu-panel .mat-menu-content button:focus {
    outline: 0 !important;
}
.mat-menu-panel .mat-menu-content button a span {
    margin-left: 10px;
}
.title_with_addbtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none !important;
}
.title_with_addbtn h5 {
    margin-bottom: 0;
}
.sitesetting-box-row {
    display: flex;
    align-items: center;
}
.upload .form-group {
    margin-bottom: 0;
}
.custom-file-input:focus ~ .custom-file-label {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.custom-file-label::after {
    background: #ff416c;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto;
    background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
    background: linear-gradient(to right, #ff4b2b, #ff416c);
    color: #fff;
    letter-spacing: 0.5px;
    font-size: 16px;
}
.custom-file-label {
    height: 37px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 14px !important;
}
.preview_img img {
    width: 100%;
}
.mat-horizontal-content-container {
    padding: 0 !important;
}
.step-summary .step-summary-box {
    text-align: center;
}
.step-summary .step-summary-box ul {
    border: 1px dashed #ccc;
    margin-bottom: 0;
    padding: 15px;
}
.step-summary .step-summary-box ul li a span i {
    font-size: 24px !important;
    color: #009da0;
}
.profile-preview-div {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: block;
    object-fit: cover;
}
.verfication_request_ttl {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.verfication_request_ttl .commonsavebtn {
    padding: 0 20px !important;
    border-radius: 3px !important;
}
.verfication_request_ttl .circleeditbtn {
    height: auto !important;
    color: #fff;
    width: auto !important;
    min-width: auto;
}
.verfication_request_ttl .circleeditbtn i {
    margin-top: -4px;
}
.verification_request .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none !important;
}
.verification-request-certificate-main-tab-div .mat-tab-group.mat-primary .mat-ink-bar,
.verification-request-certificate-main-tab-div .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: rgba(149, 165, 166, 0.3);
    height: 48px;
}
.fab-button-mobile {
    display: none;
}
.verification-request-mobile {
    display: none;
}
.blockchain_status .box {
    display: flex;
    justify-content: space-between;
}
.system-genrated .mat-form-field-wrapper {
    padding-bottom: 0;
}
.certificate-revocation-add {
    margin: 0;
}
.qrcode-img {
    border: 10px solid #eee;
    float: none;
    margin: 0 auto;
    padding: 0;
    width: 266px !important;
}
.qrcode-img canvas {
    width: 100%;
}
.qrcode-img img {
    width: 100%;
}
.plan_feature_modal {
    padding: 0;
}
.plan_feature_modal ul {
    padding: 13px;
}
.plan_feature_modal ul li {
    display: flex;
    align-items: center;
}
.plan_feature_modal ul li i {
    color: #009da0;
    margin-right: 5px;
    font-size: 15px;
}
.plan_feature_modal .ttl {
    background-color: #f6f6f6;
    padding: 10px;
    margin-bottom: 0;
}
.subscription-add {
    background-color: #f6f6f6;
    padding: 0 20px;
}
.subscription-add .box {
    max-width: 20%;
    border-right: 1px solid #dddfd4;
    padding: 20px;
}
.subscription-add .box:last-child {
    border-right: none;
}
.subscription-add .box h4 {
    margin-bottom: 0;
}
.subscription-add .box p {
    margin: 0;
}
.subscription-edit .box {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.subscription-edit .box .left,
.subscription-edit .box .right {
    width: 50%;
}
.subscription-edit .box h4,
.subscription-edit .box p {
    margin: 0;
}
.details-in-tab .box {
    margin-bottom: 20px;
}
.details-in-tab p {
    color: #424141;
}
.details-in-tab .box h4 {
    margin-bottom: 0;
}
.details-in-tab .box p {
    margin-bottom: 0;
}
.camera-box {
    float: none;
    margin: 0 auto;
    height: 350px;
    overflow: hidden;
    border: 10px solid #eee;
    padding: 0;
}
.camera-box .scanner-shell,
zxing-scanner {
    height: 100%;
}
.camera-box video {
    height: 100% !important;
}
.rw-veri-left qrcode img {
    width: 100%;
}
.viewdetails .box ul {
    background-color: #f6f6f6;
    padding: 30px;
    margin-bottom: 0;
}
.verification-request-table-mobile {
    display: none;
}
@media all and (max-width: 991px) {
    .wrapper.nav-collapsed .app-sidebar {
        width: 250px !important;
    }
    .wrapper.nav-collapsed .app-sidebar .nav-close {
        display: block !important;
    }
    .wrapper.nav-collapsed .app-sidebar .logo .text {
        display: block;
    }
    .wrapper.nav-collapsed .app-sidebar .nav-toggle {
        display: block !important;
    }
    .wrapper.nav-collapsed .app-sidebar .menu-title {
        display: block !important;
    }
    .qrcenter {
        text-align: center;
        margin-bottom: 15px;
    }
    .text-right.textcntr {
        text-align: center !important;
    }
    .mat-tab-label {
        min-width: 160px !important;
        padding: 0 2%;
    }
    .qrcode-img {
        padding: 0;
        width: 185px !important;
    }
    .mat-tab-group {
        width: 100%;
    }
    .mat-tab-body-content p {
        word-break: break-all;
    }
}
@media all and (max-width: 575px) {
    .rw-veri-left {
        min-width: 100%;
        margin-bottom: 20px;
    }
    .rw-veri-right {
        min-width: 100%;
    }
}
@media all and (max-width: 480px) {
    .verification-request-table-desk {
        display: none;
    }
    .verification-request-table-mobile {
        display: block !important;
    }
    .commonheader_breadcrumb-mobile-hide {
        display: none;
    }
}
.alert-yellow {
    background-color: #f7dc6f !important;
    border-color: #d4ac0d !important;
    color: #ac8b06 !important;
}
.no-wrap {
    white-space: nowrap;
}
.wrapper.nav-collapsed .app-sidebar .i-logo {
    display: block;
}
.wrapper.nav-collapsed .app-sidebar:hover .i-logo {
    display: none;
}
.i-logo {
    display: none;
}
.wrapper.nav-collapsed .app-sidebar .big-logo {
    display: none;
}
.wrapper.nav-collapsed .app-sidebar:hover .big-logo {
    display: block;
}
.big-logo {
    display: block;
}
.readonly-section {
    color: #424141;
}
.readonly-section .mat-form-field-appearance-outline .mat-form-field-infix {
    padding-top: 3px;
    padding-bottom: 10px;
}
.profile-page .mat-tab-body {
    padding: 10px 0;
}
.readonly-section .mat-form-field-wrapper {
    padding-bottom: 0;
}
.readonly-section .mat-select-arrow {
    border: none;
}
.instance-setting-box {
    margin-bottom: 15px;
}
.distributor-view-box {
    margin-bottom: 15px;
}
.distributor-view-box ul {
    margin-bottom: 0;
}
.distributor-view-box ul li {
    line-height: 20px;
    margin-bottom: 0 !important;
}
.distributor-view-box ul li span {
    color: #424141;
    word-break: break-all;
}
.rw-note {
    line-height: 18px;
    margin-bottom: 20px;
    display: block;
}
.break-hash a {
    width: 500px;
    display: block;
}
.name-email-img-div {
    display: flex;
}
