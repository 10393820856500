@media (max-width: 1280px) {}

@media (max-width: 1199px) {}

@media (max-width: 1024px) {}

@media (max-width: 991px) {
    .container {
        max-width: 100% !important;
    }
    .navbar {
        background-color: #3fb0ac;
        margin-bottom: 30px;
    }
    .noti-count {
        top: -6px !important;
    }
    .navbar .navbar-nav {
        margin: 7px 0px 0px 0px;
    }
    .res-navbar-nav li a {
        color: #fff!important;
        padding: 0px;
    }
    .res-navbar-nav .dropdown-menu a.dropdown-item {
        color: #303030 !important;
        padding: 0.25rem 1.5rem;
    }
    .navbar-toggle,
    .navbar-collapse-toggle {
        color: #fff;
    }
    .navbar-header .navbar-toggle,
    .navbar-header .navbar-collapse-toggle {
        margin: 0px;
    }
    .wrapper.nav-collapsed .main-panel .main-content,
    .wrapper.nav-collapsed .navbar .navbar-header {
        padding-left: 0px;
    }
    .navbar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .login_form .right {
        padding: 30px;
    }
    .navbar {
        padding: 0 15px;
    }
    .commonheader_breadcrumb {
        padding: 0px 8px;
    }
    .card {
        margin-left: -8px;
        margin-right: -10px;
    }
}

@media (max-width: 800px) {}

@media (max-width: 768px) {}

@media (max-width: 767px) {
    /* Common CSS Start */
    h1 {
        font-size: 30px !important;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 14px !important;
        margin-bottom: 0;
    }
    h5 {
        font-size: 14px;
    }
    .commonheader_breadcrumb {
        display: block;
    }
    .commonheader_breadcrumb .breadcrumb-custom-left {
        font-size: 16px;
    }
    .mat-tab-label .mat-tab-label-content {
        font-size: 14px;
    }
    .commonsavebtn {
        font-size: 14px;
        padding: 3px 22px !important;
    }
    .commonbackbtn {
        font-size: 14px;
        margin-right: 6px !important;
        padding: 3px 25px !important;
    }
    .loginbtn {
        font-size: 14px;
        padding: 3px 22px !important;
    }
    .copyright {
        font-size: 11px;
    }
    .profile-img {
        height: auto !important;
        max-width: 100px !important;
        margin: auto auto 15px;
    }
    .upload-img {
        right: 0 !important;
    }
    .upload-img .Profile-edit-img {
        width: 36px;
    }
    .profile-preview-div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .profile .card {
        min-height: auto;
    }
    .card-body {
        padding: 15px;
    }
    .common_forms {
        padding: 0px;
    }
    /* Common CSS End */
    .camera-box {
        min-width: 100%;
    }
    .login_form .left {
        display: none !important;
    }
    .login_form .right {
        border-radius: 10px;
    }
    .login .vhheight {
        margin: 0px 15px;
    }
    .forgot_password {
        padding: 0px;
    }
    .step-summary-box {
        margin-bottom: 15px;
    }
    .subscription-edit .box {
        display: block;
    }
    .subscription-edit .box .left,
    .subscription-edit .box .right {
        width: 100%;
    }
    .verification-request-mobile table th,
    .verification-request-mobile table td {
        font-size: 11px;
    }
}

@media (max-width: 640px) {}

@media (max-width: 601px) {}

@media (max-width: 575px) {
    .savebackbtndiv {
        text-align: center !important;
    }
    .qrcode-img {
        width: 50%;
    }
    .verfication_request_ttl {
        margin-bottom: 15px;
        justify-content: center;
        display: block;
    }
    .verfication_request_ttl button {
        width: 100% !important;
        min-width: 100% !important;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .navbar {
        margin-bottom: 0px !important;
    }
    .commonheader_breadcrumb {
        margin-top: 12px;
    }
    .stepper .mat-step-label {
        display: none;
    }
    .mat-tab-body {
        padding: 10px 0px;
    }
    .verification-request-desktop {
        display: none;
    }
    .verification-request-mobile {
        display: block !important;
    }
    .verification-request-mobile .mat-tab-body-content {
        overflow-y: scroll !important;
        height: 500px;
    }
    .verification-request-mobile .commonsavebtn {
        margin: 10px 0px 10px 0px;
    }
}

@media (max-width: 414px) {}

@media (max-width: 360px) {
    .remember_forgot ul {
        display: block;
        text-align: left;
    }
}

@media (max-width: 320px) {
    .navbar-right {
        display: block;
    }
    .commonrow_steps .right {
        display: block !important;
    }
    .commonrow_steps .right .plusbtndiv {
        margin: 0px 0px 20px 0px;
        text-align: right;
    }
}